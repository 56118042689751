<h2 class="title" mat-dialog-title>{{'change_password_title' | i18nSelect:locale.dict}}</h2>
<form class="change-password-form" [formGroup]="changePasswordForm"
      (ngSubmit)="changePassword(registerFormDirective)" #registerFormDirective="ngForm">
  <mat-dialog-content class="dialog-content form-inputs scrollable scrollable-y dialog-content-scrollable-fix-80vh">
    <div class="form-inputs-inner">
      <mat-form-field appearance="outline" class="form-field">
        <mat-label><label for="old-password">{{'change_password_old' | i18nSelect:locale.dict}}</label></mat-label>
        <input matInput id="old-password" formControlName="oldPassword" type="password"
               [placeholder]="'change_password_old' | i18nSelect:locale.dict">
        <mat-icon matSuffix>vpn_key</mat-icon>
      </mat-form-field>
      <mat-form-field appearance="outline" class="form-field form-field-extend">
        <mat-label><label for="new-password">{{'change_password_new' | i18nSelect:locale.dict}}</label></mat-label>
        <input matInput id="new-password" formControlName="password"
               [type]="passwordVisible ? 'text' : 'password'"
               [placeholder]="'change_password_new' | i18nSelect:locale.dict">
        <button mat-button mat-icon-button type="button" matSuffix
                (click)="passwordVisible = !passwordVisible" class="password-visible-toggle"
                [matTooltip]="(passwordVisible ? 'register_password_hide' : 'register_password_see') | i18nSelect:locale.dict">
          <mat-icon>{{passwordVisible ? 'visibility_off' : 'visibility'}}</mat-icon>
        </button>
        <mat-hint *ngIf="!changePasswordForm.controls.password.hasError('weakPassword')">
          {{'register_hint_password' | i18nSelect:locale.dict}}
        </mat-hint>
        <mat-error *ngIf="changePasswordForm.controls.password.hasError('weakPassword')">
          {{'register_error_password_weak' | i18nSelect:locale.dict}}
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" class="form-field">
        <mat-label>
          <label for="password-repeat">{{'register_password_repeat' | i18nSelect:locale.dict}}</label>
        </mat-label>
        <input matInput id="password-repeat" formControlName="passwordConfirm"
               [type]="passwordVisible ? 'text' : 'password'"
               [placeholder]="'register_password_repeat' | i18nSelect:locale.dict"
               [errorStateMatcher]="passwordErrorStateMatcher">
        <button mat-button mat-icon-button type="button" matSuffix
                (click)="passwordVisible = !passwordVisible" class="password-visible-toggle"
                [matTooltip]="(passwordVisible ? 'register_password_hide' : 'register_password_see') | i18nSelect:locale.dict">
          <mat-icon>{{passwordVisible ? 'visibility_off' : 'visibility'}}</mat-icon>
        </button>
        <mat-error *ngIf="changePasswordForm.hasError('passwordMismatch')">
          {{'register_error_password_mismatch' | i18nSelect:locale.dict}}
        </mat-error>
      </mat-form-field>
    </div>
  </mat-dialog-content>
  <div mat-dialog-actions class="dialog-actions">
    <button mat-button mat-stroked-button [mat-dialog-close]="false" type="button">
      {{'change_password_cancel' | i18nSelect:locale.dict}}
    </button>
    <button mat-flat-button class="transitioned-button strong-hover"
            color="primary" [disabled]="changePasswordForm.invalid" type="submit">
      {{'change_password_submit' | i18nSelect:locale.dict}}
    </button>
  </div>
</form>
