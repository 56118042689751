<h2 class="title" mat-dialog-title>{{(config.type + '_choice_title') | i18nSelect:locale.dict}}</h2>
<mat-dialog-content class="dialog-content">
  <p class="dialog-message">
    {{(config.type + '_choice_word') | i18nSelect:locale.dict}}
  </p>
</mat-dialog-content>
<div mat-dialog-actions class="dialog-actions">
  <button mat-button mat-stroked-button [mat-dialog-close]="false" color="primary">
    {{(config.type + '_choice_left') | i18nSelect:locale.dict}}
  </button>
  <button mat-flat-button class="transitioned-button strong-hover" *ngIf="config.different"
          [mat-dialog-close]="true" color="primary">
    {{(config.type + '_choice_right') | i18nSelect:locale.dict}}
  </button>
  <button mat-button mat-stroked-button [mat-dialog-close]="true" color="primary" *ngIf="!config.different">
    {{(config.type + '_choice_right') | i18nSelect:locale.dict}}
  </button>
</div>
