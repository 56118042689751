<div class="page-container scrollable" #container>
  <div class="top-padding" style="height: 80px; width: 100%"></div>

  <mat-tab-group dynamicHeight class="tab-group">

    <mat-tab [label]="'admin_user' | i18nSelect:locale.dict">
      <div class="user-tab tab">
        <div class="user-table-top table-top">
          <mat-form-field class="tag-form-field">
            <mat-label>
              <label for="user-filter-input">{{'admin_user_search' | i18nSelect:locale.dict}}</label>
            </mat-label>
            <input matInput
                   id="user-filter-input"
                   type="text"
                   [(ngModel)]="userFilter"
                   (keyup.enter)="userApplyFilter()"
                   [placeholder]="'admin_user_search_placeholder' | i18nSelect:locale.dict">
          </mat-form-field>
          <button mat-fab color="primary"
                  [matTooltip]="'admin_user_add' | i18nSelect:locale.dict" (click)="userAdd()">
            <mat-icon>person_add_alt_1</mat-icon>
          </button>
          <button mat-fab color="warn"
                  [disabled]="userSelection.isEmpty()" (click)="userDelete(userSelection.selected, true)"
                  [matTooltip]="'admin_delete' | i18nSelect:locale.dict">
            <mat-icon>delete</mat-icon>
          </button>
        </div>
        <div class="table-container scrollable">
          <table mat-table [dataSource]="userDataSource" class="user-table table">
            <ng-container matColumnDef="select">
              <th mat-header-cell *matHeaderCellDef>
                <mat-checkbox (change)="$event ? userMasterToggle() : null"
                              [checked]="userSelection.hasValue() && userIsAllSelected()"
                              [indeterminate]="userSelection.hasValue() && !userIsAllSelected()">
                </mat-checkbox>
              </th>
              <td mat-cell *matCellDef="let row">
                <mat-checkbox (click)="$event.stopPropagation()"
                              (change)="$event ? userSelection.toggle(row) : null"
                              [checked]="userSelection.isSelected(row)">
                </mat-checkbox>
              </td>
            </ng-container>

            <ng-container matColumnDef="user" sticky>
              <th mat-header-cell class="user-column" *matHeaderCellDef>
                {{'admin_user_username' | i18nSelect:locale.dict}}
              </th>
              <td mat-cell *matCellDef="let element">
                <div class="user-wrapper">
                  <div class="user-avatar" [matTooltip]="element.name">
                    <img #realAvatar
                         (error)="realAvatar.classList.add('errored'); pseudoAvatar.classList.add('errored')"
                         [src]="'/avatar/' + element.id + '.i' + getRefresh(element.id)" alt="avatar" class="avatar-image">
                    <div #pseudoAvatar class="avatar-image pseudo-avatar-image">
                      <mat-icon>person</mat-icon>
                    </div>
                  </div>
                  <span class="user-name">
                    {{element.name}}
                  </span>
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="privileged">
              <th mat-header-cell class="user-column" *matHeaderCellDef>
                {{'admin_user_privileged' | i18nSelect:locale.dict}}
              </th>
              <td mat-cell *matCellDef="let element">
                <mat-checkbox [checked]="element.privileged" [disabled]="userUpdating"
                              (change)="userSetPermission(element, true, false)"></mat-checkbox>
              </td>
            </ng-container>

            <ng-container matColumnDef="frozen">
              <th mat-header-cell class="user-column" *matHeaderCellDef>
                {{'admin_user_frozen' | i18nSelect:locale.dict}}
              </th>
              <td mat-cell *matCellDef="let element">
                <mat-checkbox [checked]="element.frozen" [disabled]="userUpdating"
                              (change)="userSetPermission(element, false, true)"></mat-checkbox>
              </td>
            </ng-container>

            <ng-container matColumnDef="operation">
              <th mat-header-cell *matHeaderCellDef>
                {{'admin_operation' | i18nSelect:locale.dict}}
              </th>
              <td mat-cell *matCellDef="let element">
                <a mat-button mat-icon-button
                   [routerLink]="['/gallery', element.id]"
                   [matTooltip]="'admin_user_check_gallery' | i18nSelect:locale.dict">
                  <mat-icon>collections</mat-icon>
                </a>
                <button mat-button mat-icon-button
                        (click)="userSetAvatar(element)"
                        [disabled]="userUpdating"
                        [matTooltip]="'admin_user_set_avatar' | i18nSelect:locale.dict">
                  <mat-icon>account_circle</mat-icon>
                </button>
                <button mat-button mat-icon-button
                        (click)="userSetPassword(element)"
                        [disabled]="userUpdating"
                        [matTooltip]="'admin_user_set_password' | i18nSelect:locale.dict">
                  <mat-icon>vpn_key</mat-icon>
                </button>
                <button mat-button mat-icon-button
                        (click)="userDelete(element, false)"
                        [disabled]="userUpdating"
                        [matTooltip]="'admin_delete' | i18nSelect:locale.dict">
                  <mat-icon>delete</mat-icon>
                </button>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="userColumns"></tr>
            <tr class="user-row" mat-row *matRowDef="let element; columns: userColumns;"></tr>
          </table>
          <mat-paginator #userPaginator="matPaginator"
                         class="user-paginator paginator"
                         [pageSizeOptions]="[10, 50, 100]"
                         showFirstLastButtons pageSize="10"
                         (page)="userSelection.clear()"></mat-paginator>
        </div>
      </div>


    </mat-tab>

    <mat-tab [label]="'admin_code' | i18nSelect:locale.dict">
      <div class="code-tab tab">
        <div class="code-table-top table-top">
          <button mat-fab color="primary"
                  (click)="codeAdd()"
                  [matTooltip]="'admin_code_add' | i18nSelect:locale.dict">
            <mat-icon>add</mat-icon>
          </button>
        </div>
        <div class="table-container scrollable">
          <table mat-table [dataSource]="codeDataSource" class="code-table table">
            <ng-container matColumnDef="select">
              <th mat-header-cell *matHeaderCellDef>
                <mat-checkbox (change)="$event ? codeMasterToggle() : null"
                              [checked]="codeSelection.hasValue() && codeIsAllSelected()"
                              [indeterminate]="codeSelection.hasValue() && !codeIsAllSelected()">
                </mat-checkbox>
              </th>
              <td mat-cell *matCellDef="let row">
                <mat-checkbox (click)="$event.stopPropagation()"
                              (change)="$event ? codeSelection.toggle(row) : null"
                              [checked]="codeSelection.isSelected(row)">
                </mat-checkbox>
              </td>
            </ng-container>

            <ng-container matColumnDef="code" sticky>
              <th mat-header-cell class="code-column" *matHeaderCellDef>
                {{'admin_code_code' | i18nSelect:locale.dict}}
              </th>
              <td mat-cell *matCellDef="let element">
                <div class="code-content-wrapper">
                  <span class="code-content" [matTooltip]="element.code">
                    {{element.code}}
                  </span>
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="times" sticky>
              <th mat-header-cell class="user-column" *matHeaderCellDef>
                {{'admin_code_times' | i18nSelect:locale.dict}}
              </th>
              <td mat-cell *matCellDef="let element">
                <span class="code-times">
                  {{element.times}}
                </span>
              </td>
            </ng-container>

            <ng-container matColumnDef="operation">
              <th mat-header-cell *matHeaderCellDef>
                {{'admin_operation' | i18nSelect:locale.dict}}
              </th>
              <td mat-cell *matCellDef="let element">
                <button mat-button mat-icon-button
                        [disabled]="codeUpdating"
                        (click)="codeSetTimes(element)"
                        [matTooltip]="'admin_code_set_times' | i18nSelect:locale.dict">
                  <mat-icon>create</mat-icon>
                </button>
                <button mat-button mat-icon-button
                        [disabled]="codeUpdating"
                        (click)="codeDelete(element)"
                        [matTooltip]="'admin_delete' | i18nSelect:locale.dict">
                  <mat-icon>delete</mat-icon>
                </button>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="codeColumns"></tr>
            <tr class="code-row" mat-row *matRowDef="let element; columns: codeColumns;"></tr>
          </table>
          <mat-paginator #codePaginator="matPaginator"
                         class="code-paginator paginator"
                         [pageSizeOptions]="[10, 50, 100]"
                         showFirstLastButtons pageSize="10"
                         (page)="codeSelection.clear()"></mat-paginator>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
