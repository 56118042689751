<div class="picker-wrapper">
  <button mat-fab [matMenuTriggerFor]="menu" color="primary" *ngIf="iconStyle === 'fab'"
          class="transitioned-button strong-hover"
          [matTooltip]="'language_switch' | i18nSelect:locale.dict" #menuTrigger="matMenuTrigger">
    <mat-icon>translate</mat-icon>
  </button>
  <button mat-button mat-icon-button [matMenuTriggerFor]="menu" *ngIf="iconStyle === 'icon'"
          [matTooltip]="'language_switch' | i18nSelect:locale.dict" #menuTrigger="matMenuTrigger">
    <mat-icon>translate</mat-icon>
  </button>
  <mat-menu #menu="matMenu">
    <button mat-menu-item *ngFor="let lang of languages" (click)="setLanguage(lang[0])">{{lang[1]}}</button>
  </mat-menu>
</div>
